<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">专题管理（小程序）</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">专题列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="专题名称" class="searchboxItem ci-full">
              <span class="itemLabel">专题名称:</span>
              <el-input
                v-model="specialSubjectName"
                type="text"
                size="small"
                clearable
                placeholder="请输入专题名称"
              />
            </div>
            <div title="状态" class="searchboxItem ci-full">
          <span class="itemLabel">状态:</span>
          <el-select
            v-model="publish"
            placeholder="请选择"
            clearable
            size="small"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
            <div title="模板" class="searchboxItem ci-full">
          <span class="itemLabel">模板:</span>
          <el-select
            v-model="templateType"
            placeholder="请选择"
            clearable
            size="small"
          >
            <el-option
              v-for="item in specialTemplateTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
            <!-- <div title="关联机构" class="searchboxItem ci-full">
              <span class="itemLabel">关联机构:</span>
              <el-select
                size="small"
                v-model="compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div> -->
            <el-button
              style="margin-left: 20px"
              class="bgc-bv"
              round
              @click="getData()"
              >查询</el-button
            >
            <div class="btnBox" style="margin-left: 20px">
              <el-button class="bgc-bv" round @click="handleCreate('add')"
                >新增专题</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
                @cell-mouse-enter="M_Enter"
              @cell-mouse-leave="m_leave"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />

              <el-table-column
                label="专题名称"
                align="center"
                prop="specialSubjectName"
                show-overflow-tooltip
                min-width="100"
              />

              <el-table-column
                label="专题模板"
                align="center"
                prop="templateType"
                show-overflow-tooltip
              >
              <template slot-scope="scope">
                  {{$setDictionary('SPECIAL_TEMPLATE_TYPE',scope.row.templateType)}}
              </template>
              </el-table-column>
              <el-table-column
                label="专题课程"
                align="center"
                prop="courseName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="创建时间"
                align="center"
                prop="createTime"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="显示顺序"
                align="center"
                prop="sort"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
              <div v-if="!scope.row.orderShow">
                <span> {{ scope.row.sort }}</span>
                <i
                  class="el-icon-edit"
                  v-show="scope.row.showInput"
                  @click="changeOrder(scope.row)"
                ></i>
              </div>
              <el-input
                v-else
                v-model="scope.row.sort"
                maxlength="9"
                size="small"
                @blur="
                  () => {
                    editRowSort(scope.row);
                  }
                "
              ></el-input>
            </template>
              </el-table-column>
              <el-table-column
                label="状态"
                align="center"
                prop="publish"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.publish ? '已上架':'未上架'}}
                </template>
              </el-table-column>

              <el-table-column
                label="操作"
                align="center"
                width="260"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleCreate('edit', scope.row.specialSubjectId)"
                    >编辑
                  </el-button>
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 7px 10px"
                    @click="handleDel(scope.row.specialSubjectId)"
                    >删除</el-button
                  >
                    <el-button type="text" size="mini" @click="handleUpdateStateOrSort(scope.row)">{{scope.row.publish ? '下架':'上架'}}</el-button>
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 7px 10px"
                    @click="seeShare(scope.row)"
                    >查看分享码</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <!-- 查看分享吗的弹框 -->
    <el-dialog
      :title="seeShareData.title + '-分享码'"
      :visible.sync="seeShareDialog"
      width="30%"
    >
      <img
        id="parter"
        style="width: 60%; display: block; margin: auto"
        :src="seeShareData.url"
        alt=""
      />
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          class="bgc-bv"
          size="small"
          round
          @click="downloadShare"
          >下载小程序码</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import html2canvas from "html2canvas";
export default {
  name: "teacherList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      specialSubjectName: "", //专题名称
      publish:'',
      templateType:'',
      //  二维码弹框数据
      seeShareData: {
        url: "", // 二维码路径
        title: "", // 弹框标题
      },
      // 二维码弹框 - 状态
      seeShareDialog: false,
       options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: true,
          label: "已上架",
        },
        {
          value: false,
          label: "未上架",
        },
      ],
      specialTemplateTypeList:[],
    };
  },
  computed: {},
  created() {
    this.getTableHeight();
    this.getSpecialTemplateType()
  },
  methods: {
       //获取模版
    getSpecialTemplateType() {
      const specialTemplateType =
        this.$setDictionary("SPECIAL_TEMPLATE_TYPE", "list") || [];
      let list = [];
      for (const key in specialTemplateType) {
        list.push({
          value: key,
          label: specialTemplateType[key],
        });
      }
      this.specialTemplateTypeList = list;
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        specialSubjectName: this.specialSubjectName,
        publish: this.publish,
        templateType:this.templateType,
      };
      this.doFetch(
        {
          url: "/miniapp/special/subject/comp/pageList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    // 新增
    handleCreate(stu, specialSubjectId = "") {
      this.$router.push({
        path: "/web/miniApp/thematicEditJg",
        query: { specialSubjectId, stu },
      });
    },
    // 删除
    handleDel(specialSubjectId) {
      this.$confirm("确定删除该专题吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
        .then(() => {
          this.$post(
            "/miniapp/special/subject/delete",
            { specialSubjectId },
            3000,
            true,
            2
          ).then((res) => {
            this.getData();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //上架下架
    handleUpdateStateOrSort(row) {
        // if (row.publish) {
                 this.doDel({
          title: row.publish?"下架":'上架',
          url: "/miniapp/special/subject/comp/updateStateOrSort",
          msg: '你确定要'+(row.publish?'下架':'上架')+'该专题吗？',
          ps: {
            type: "post",
            data: { specialSubjectId: row.specialSubjectId,publish: !row.publish}
          }
        },true,2);
        // }
       
        // this.$post('/miniapp/special/subject/comp/updateStateOrSort',{
        //     publish,
        //     specialSubjectId:row.specialSubjectId
        // })
    },
    // 查看分享吗
    seeShare(row) {
      this.seeShareData.title = row.specialSubjectName;
      this.seeShareData.url = row.qrcodeKey;
      this.seeShareDialog = true;
    },
    // 下载小程序码
    downloadShare() {
      window.open(this.seeShareData.url);
    },
    // 关联单位
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
        handleListDataBack(data) {
      this.tableData = [];
      data.map((el) => {
        el.showInput = false;
        el.orderShow = false;
        this.tableData.push(el);
      });
    },
    // 鼠标进入事件
    M_Enter(row, column, cell, event) {
      console.log(row, column, cell, event);
      if (column.label == "显示顺序") {
        row.showInput = true;
      }
    },
    // 鼠标离开事件
    m_leave(row, column, cell, event) {
      row.showInput = false;
    },
    // 点击笔
    changeOrder(row) {
      ;
      row.orderShow = !row.orderShow;
    },
    //输入框失去焦点 保存修改
    editRowSort(row) {
      const params = {
        specialSubjectId: row.specialSubjectId,
        sort: row.sort,
      };
      this.$post(
        "/miniapp/special/subject/comp/updateStateOrSort",
        params,
        3000,
        true,
        2
      ).then((res) => {
        ;
        this.$message({
          type: "success",
          message: "修改成功",
        });
        this.getData(-1);
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh == true) {
          this.getData(-1);
        }
        if (oldVal.query.stu == "add") {
          (this.pageNum = 1), this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}

.operationControl > div > div > span {
  min-width: 6rem;
}

.itemLabel {
  display: inline-block;
  width: 3rem;
}
</style>
<style lang="less" scoped></style>