import { render, staticRenderFns } from "./thematicListJg.vue?vue&type=template&id=3555b33e&scoped=true"
import script from "./thematicListJg.vue?vue&type=script&lang=js"
export * from "./thematicListJg.vue?vue&type=script&lang=js"
import style0 from "./thematicListJg.vue?vue&type=style&index=0&id=3555b33e&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3555b33e",
  null
  
)

export default component.exports